import { useState, useEffect } from "react"

function Header() {
  const [isActive, setActive] = useState(true);
  const [isDropdownActive, setDropdownActive] = useState(false);
  const companyEmail = "info@mfvabogados.com.ar"
  const companyPhone = "11-2408-9830"
  const toggleClass = () => {
    setActive(!isActive);
  };
  const hideMobileMenu = () => {
    setActive(true);
  };
  const ToggleDropdown = () => {
    setDropdownActive(!isDropdownActive);
  };
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10)
    })
  }, [])
  
  return (
    <>
<div>
  {/* ======= Top Bar ======= */}
  <section id="topbar" className="d-flex align-items-center">
    <div className="container d-flex justify-content-center justify-content-md-between">
      <div className="contact-info d-flex align-items-center">
        <i className="bi bi-envelope-fill" /><a href={`mailto:${companyEmail}`}>{companyEmail}</a>
        <i className="bi bi-phone-fill phone-icon" /><a href={`https://wa.me/+549${companyPhone.replace(/-/g, '')}?text=Hola%20necesito%20asesoramiento`}>{companyPhone}</a>
      </div>
      <div className="social-links d-none d-md-block">
        {/* <a href="#" className="twitter"><i className="bi bi-twitter" /></a> */}
        <a href="https://www.facebook.com/your.mfvabogado" className="facebook"><i className="bi bi-facebook" /></a>
        <a href="https://www.instagram.com/mfvabogados/" className="instagram"><i className="bi bi-instagram" /></a>
        <a href="https://linkedin.com/company/mfvabogados" className="linkedin"><i className="bi bi-linkedin" /></a>
      </div>
    </div>
  </section>
{/* ======= Header ======= */}
<header id="header" className={scroll ? "d-flex align-items-center fixed-top": "d-flex align-items-center"}>
  <div className="container d-flex align-items-center justify-content-between">
    {/*<h1 className="logo"><a href="index.html">MFV Abogados</a></h1>*/}
    {/* Uncomment below if you prefer to use an image logo */}
    <a href="/" className="logo"><img alt="" className="img-fluid" src={require('../MFV_LOGO_1.png')}/></a>
    <nav id="navbar" className={ isActive ? "navbar" : "navbar navbar-mobile"}>
      <ul>
        <li><a className="nav-link scrollto active" href="/" onClick={hideMobileMenu}>Inicio</a></li>
        <li><a className="nav-link scrollto" href="/#acerca-de" onClick={hideMobileMenu}>Acerca de</a></li>
        <li className="dropdown"><a href={isActive ? "/#servicios":"#" } onClick={ToggleDropdown}><span>Servicios</span> <i className="bi bi-chevron-down" /></a>
          <ul className={isDropdownActive ? "dropdown-active": null}>
            <li><a href="/accidentes-trabajo" onClick={hideMobileMenu}>Accidentes de trabajo</a></li>
            <li><a href="/enfermedades-profesionales" onClick={hideMobileMenu}>Enfermedades profesionales</a></li>
            <li><a href="/ciudadania-italiana" onClick={hideMobileMenu}>Ciudadanía Italiana</a></li>
            <li><a href="/ciudadania-espanola" onClick={hideMobileMenu}>Ciudadanía Española</a></li>
          </ul>
        </li>
        {/*<li><a className="nav-link scrollto" href="/#equipo" onClick={hideMobileMenu}>Equipo</a></li>*/}
        <li><a className="nav-link scrollto" href="/#contacto" onClick={hideMobileMenu}>Contacto</a></li>
      </ul>
      <i className={isActive ? "bi bi-list mobile-nav-toggle" : "bi bi-x mobile-nav-toggle"} onClick={toggleClass}/>
    </nav>{/* .navbar */}
  </div>
</header>{/* End Header */}

</div>

    </>
  );
}

export default Header;
