import Header from './Header'
import Footer from './Footer'

function AccidentesTrabajo() {
    const title =  'Enfermedades Profesionales'
    return (
        <>
            <Header/>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section id="breadcrumbs" className="breadcrumbs">
                  <div className="container">
                    <ol>
                      <li><a href="/">Inicio</a></li>
                      <li>{title}</li>
                    </ol>
                    {/*h2>{title}</h2*/}
                    <div className="row">
                      <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                        <img src="assets/img/enfermedades-profesionales.png" className="img-fluid" alt="enfermedades profesionales" style={{'padding': '8%'}} />
                      </div>
                      <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                        <h3>{title}</h3>
                        <p className="fst-itali justified-text">
                          Toda enfermedad <strong>dentro o fuera del listado de Enfermedades Profesionales (<a href='https://servicios.infoleg.gob.ar/infolegInternet/anexos/225000-229999/225309/norma.htm' className='black-link'>Dec.49/2014</a>)</strong> que se haya producido exclusivamente por la labor diaria del trabajador.
                        </p>
                        <ul>
                          <li><i className="bi bi-check-circle" /> Si su ART le rechazó su enfermedad profesional, realizamos su reclamo ante la Superintendencia de Riesgos de trabajo.</li>
                          <li><i className="bi bi-check-circle" /> Reclamos judiciales contra su ART a fin de que se reconozca el mayor porcentaje de incapacidad de acuerdo a su lesión.</li>
                        </ul>
                        <p className="fst-itali justified-text">
                        Ofrecemos representación tanto en la instancia administrativa como judicial en donde nuestro principal objetivo es obtener con la ayuda de nuestros médicos el mayor porcentaje de incapacidad física y psíquica de acuerdo a su lesión. 
                        </p>
                        <p className="fst-itali justified-text"> Por consultas o inquietudes podés realizar tu consulta al <a className='black-link' href="https://wa.me/+5491124089830?text=Hola%20necesito%20asesoramiento">11-2408-9830.</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </section>{/* End Breadcrumbs */}
            </main>
            <Footer/>
        </>
    );
}

export default AccidentesTrabajo;
