

function Footer() {
  const companyPhone = "11-2408-9830"
  const companyEmail = "info@mfvabogados.com.ar"
  return (
    <>
{/* ======= Footer ======= */}
<footer id="footer">
  <div className="footer-top">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6">
        <div className="footer-info">
            <p>
              Av. Córdoba 1351 <br />
              CP: 1055, CABA<br /><br />
              <strong>Teléfono:</strong> <a href={`https://wa.me/+549${companyPhone.replace(/-/g, '')}?text=Hola%20necesito%20asesoramiento`}>{companyPhone}</a><br/>
              <strong>Email:</strong> <a href={`mailto:${companyEmail}`}>{companyEmail}</a><br />
            </p>
            <div className="social-links mt-3">
              <a href="https://www.facebook.com/your.mfvabogado" className="facebook"><i className="bx bxl-facebook" /></a>
              <a href="https://www.instagram.com/mfvabogados/" className="instagram"><i className="bx bxl-instagram" /></a>
              <a href="https://linkedin.com/company/mfvabogados" className="linkedin"><i className="bx bxl-linkedin" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-6 footer-links">
          <h4>Links útiles</h4>
          <ul>
            <li><i className="bx bx-chevron-right" /> <a href="/#hero">Inicio</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/#acerca-de">Acerca de</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/#servicios">Servicios</a></li>
          </ul>
        </div>
        <div className="col-lg-2 col-md-6 footer-links">
          <h4>Nuestros Servicios</h4>
          <ul>
            <li><i className="bx bx-chevron-right" /> <a href="/accidentes-trabajo">Accidentes de trabajo</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/enfermedades-profesionales">Enfermedades profesionales</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/ciudadania-italiana">Ciudadanía Italiana</a></li>
            <li><i className="bx bx-chevron-right" /> <a href="/ciudadania-espanola">Ciudadanía Española</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="copyright">
      © Copyright <strong><span>MFV Abogados</span></strong>. All Rights Reserved
    </div>
  </div>
</footer>{/* End Footer */}

    </>
  );
}

export default Footer;
