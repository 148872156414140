import Header from './Header'
import Footer from './Footer'

function NotFound() {
    return (
        <>
            <Header/>
            <div className="d-flex align-items-center justify-content-center vh-100">
              <div className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
                <p className="fs-3"> <span className="text-danger">Upps!</span> Página no encontrada.</p>
                <p className="lead">
                  La página que estas buscando no existe.
                </p>
                <a href="/" className="btn btn-primary home-button">Inicio</a>
              </div>
            </div>
            <Footer/>
        </>
    );
}

export default NotFound;
