import Header from './Header'
import Footer from './Footer'

function EnfermedadesProfesionales() {
    const title =  'Accidentes de trabajo e in itinere'
    return (
        <>
            <Header/>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section id="breadcrumbs" className="breadcrumbs">
                  <div className="container">
                    <ol>
                      <li><a href="/">Inicio</a></li>
                      <li>{title}</li>
                    </ol>
                    {/*h2>{title}</h2*/}
                    <div className="row">
                      <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                        <img src="assets/img/accidente.png" className="img-fluid" alt="accidentes de trabajo 1" style={{'padding': '5%'}}/>
                        <img id="img-2" src="assets/img/accidente2.png" className="img-fluid" alt="accidentes de trabajo 2" style={{'padding': '5%'}}/>
                      </div>
                      <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                        <h3>{title}</h3>
                        <p className="fst-italic justified-text">
                          Entendemos que enfrentar un <strong>accidente de trabajo y/o in itinere</strong> puede ser abrumador.</p>
                        <p className="justified-text"> Queremos que sepas que en <strong>MFV ABOGADOS</strong> estamos para representarte y brindarte la asesoría legal que necesitas en ese difícil momento.
                        </p>
                        <h3>¿Qué es un accidente de trabajo?</h3>
                        <p className="justified-text">  
                          Es cualquier evento inesperado que ocurre durante el ejercicio de una actividad laboral y que resulta en daño o lesión para el trabajador. Estos accidentes pueden suceder en cualquier tipo de entorno laboral, ya sea una oficina, una fábrica, un sitio de construcción, entre otros. 
                          Algunos ejemplos comunes de accidentes de trabajo incluyen caídas, lesiones por maquinaria, cortaduras, y cualquier otro suceso imprevisto que cause daño físico o mental al trabajador mientras está cumpliendo con sus responsabilidades laborales. 
                        </p>
                        <h3>¿Qué es un accidente in itinere? </h3>
                        <p className="justified-text">Un accidente in itinere es aquel que ocurre cuando un trabajador se dirige desde su casa hacia el lugar de trabajo o viceversa. No debes haberte desviado de tu camino habitual para que tu ART te acepte el siniestro. </p>
                        <p className="justified-text">Es importante que recuerdes el trayecto realizado ya que no solo te lo puede solicitar tu aseguradora sino que en caso de ir a juicio contra tu ART se debe especificar el mismo a fin de que el juez vea que sucedió el accidente yendo o volviendo de tu lugar de trabajo. </p>
                        <p className="justified-text">
                        <strong>Si sufriste un accidente de trabajo y/o in itinere, estamos para ayudarte. Nuestro equipo te representará ante las Comisiones Médicas de la Superintendencia de Riesgos del Trabajo.</strong>
                          
                        </p>
                        <ul>
                          <li><i className="bi bi-check-circle" /> RECLAMO ADMINISTRATIVO POR TU INCAPACIDAD ANTE LA SUPERINTENDENCIA DE RIESGOS DEL TRABAJO</li>
                          <li><i className="bi bi-check-circle" /> RECLAMO A TU ART POR INCUMPLIMIENTOS ANTE LA SUPERINTENDENCIA DE RIESGOS DEL TRABAJO</li>
                          <li><i className="bi bi-check-circle" /> RECLAMO POR RECHAZO DE LA DENUNCIA QUE HICISTE EN TU ART</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>{/* End Breadcrumbs */}
            </main>
            <Footer/>
        </>
    );
}

export default EnfermedadesProfesionales;
