import axios from 'axios';

const API_BASE_URL = 'api.mfvabogados.com.ar'

const instance = axios.create({
  baseURL: `https://${API_BASE_URL}`,
  timeout: 10000
});
const originHeader = {
  "x-origin-verify":  process.env.REACT_APP_ORIGIN_HEADER
}

const sendEmail = async (data) => {
  try {
    const response = await instance.post('/send_email', data, {
      crossDomain: true,
      headers: originHeader
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const validateCaptcha = async (challenge) => {
  try {
    var data = {
      response: challenge
    }
    const response = await instance.post('/recaptcha', data, {
      crossDomain: true,
      headers: originHeader
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {sendEmail, validateCaptcha}