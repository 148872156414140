import Header from './Header'
import Footer from './Footer'
import Contact from './Contact'

function Main() {
  return (
    <>
      <Header/>
      {/* ======= Hero Section ======= */}
      <section id="hero" className="d-flex align-items-center">
        <div className="container position-relative" data-aos="fade-up" data-aos-delay={500}>
          <h1>Bienvenidos a MFV</h1>
            <ul className='intro-list'>
              <li><i className="bi bi-check-circle" /> Servicios de gestoría para obtención de ciudadanías <strong>italiana</strong> y <strong>española</strong></li>
              <li><i className="bi bi-check-circle" /> Cobro de indemnizaciones por <strong>accidentes de trabajo, in itinere y enfermedades profesionales</strong>.</li>
            </ul>
          <a href="https://wa.me/+5491124089830?text=Hola%20necesito%20asesoramiento" className="btn-get-started scrollto">Consulta sin cargo</a>
        </div>
      </section>{/* End Hero */}

      <main id="main">
        {/* ======= About Section ======= */}
        <section id="acerca-de" className="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                <img src="assets/img/acerca-de.png" alt="acerca de" className="img-fluid"  />
              </div>
              <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                <h3 className="all-capitals">Acerca de nosotros</h3>
                <br/>
                <p className="justified-text">En <strong>MFV ABOGADOS</strong> nos especializamos en la representación de los trabajadores afectados por <strong>accidentes de trabajo, accidentes in itinere y enfermedades profesionales</strong>. Nuestro equipo legal altamente capacitado se encarga de todo el proceso, desde la documentación inicial hasta la obtención de tu indemnización, asegurando que nuestros clientes obtengan la compensación que merecen por las lesiones sufridas.</p>
                <p className="justified-text">Además de nuestra especialización en <strong>indemnizaciones laborales</strong>, ofrecemos servicios de gestoría para aquellos que buscan obtener la <strong>ciudadanía italiana y española</strong>.</p>
                <p className="justified-text">Nuestro equipo se encarga tanto de la <strong>búsqueda de actas, traducciones, apostillas y legalizaciones</strong> correspondientes para que tengas lista tu carpeta para tu turno en el consulado. De este modo facilitamos el proceso permitiendo a nuestros clientes obtener la ciudadanía de manera eficiente.</p>
                {/*<ul>
                  <li><i className="bi bi-check-circle" /> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                  <li><i className="bi bi-check-circle" /> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                  <li><i className="bi bi-check-circle" /> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                </ul>*/}
              </div>
            </div>
          </div>
        </section>{/* End About Section */}
      <div>
        {/* ======= Why Us Section ======= */}
        <section id="why-us" className="why-us">
          <div className="container">
            <div className="row">
                <div className="section-title">
                  <span>Nuestros Valores</span>
                  <h2>Nuestros Valores</h2>
                </div>
              <div className="col-lg-4" data-aos="fade-up">
                <div className="box">
                  <span>01</span>
                  <h4>Compromiso Ético</h4>
                  <p className="justified-text">Guiados por principios éticos sólidos, trabajamos incansablemente para garantizar que los derechos de nuestros clientes sean protegidos y que reciban la mejor indemnización para el caso concreto.<br/><br/> </p>
                </div>
              </div>
              <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={150}>
                <div className="box">
                  <span>02</span>
                  <h4>Transparencia y Comunicación</h4>
                  <p className="justified-text">Mantenemos una comunicación abierta y transparente con nuestros clientes en cada paso del proceso legal. La claridad en la información proporcionada es fundamental para construir una relación de confianza duradera.</p>
                </div>
              </div>
              <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={300}>
                <div className="box">
                  <span>03</span>
                  <h4>Profesionalismo y Excelencia</h4>
                  <p className="justified-text">Nuestro equipo de abogados altamente capacitados se esfuerza por la excelencia en cada caso, brindando servicios legales de calidad y buscando constantemente superar las expectativas de nuestros clientes.</p>
                </div>
              </div>
            </div>
          </div>
        </section>{/* End Why Us Section */}
        {/* ======= Clients Section ======= */}
        {/*<section id="clients" className="clients">
          <div className="container" data-aos="zoom-in">
            <div className="row d-flex align-items-center">
              <div className="col-lg-2 col-md-4 col-6">
                <img src="assets/img/clients/client-1.png" className="img-fluid"  />
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <img src="assets/img/clients/client-2.png" className="img-fluid"  />
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <img src="assets/img/clients/client-3.png" className="img-fluid"  />
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <img src="assets/img/clients/client-4.png" className="img-fluid"  />
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <img src="assets/img/clients/client-5.png" className="img-fluid"  />
              </div>
              <div className="col-lg-2 col-md-4 col-6">
                <img src="assets/img/clients/client-6.png" className="img-fluid"  />
              </div>
            </div>
          </div>
        </section>{/* End Clients Section */}
        {/* ======= Services Section ======= */}
        <section id="servicios" className="services">
          <div className="container">
            <div className="section-title">
              <span>Nuestros Servicios</span>
              <h2>Nuestros Servicios</h2>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up">
                <a href="/accidentes-trabajo">
                  <div className="icon-box">
                    <div className="icon"><i className="bx bx-hard-hat" /></div>
                    <h4>Accidentes de trabajo</h4>
                    <br/>
                    <p>Cobro admnistrativo y/o judicial de indemnizaciones por accidentes de trabajo y/o in itinere.</p>
                    <br/>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={150}>
                <a href="/enfermedades-profesionales">
                  <div className="icon-box" id="enfermedades-profesionales">
                    <div className="icon"><i className="bx bx-injection" /></div>
                    <h4>Enfermedades profesionales</h4>
                    <p>Cobro administrativo y/o judicial de indemnizaciones por enfermedades profesionales.</p>
                    <br/>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={450}>
                <a href="/ciudadania-italiana">
                  <div className="icon-box">
                    <div className="icon"><i className="bx bx-world" /></div>
                    <h4>Ciudadanía Italiana</h4>
                    <br/>
                    <p>Ofrecemos servicios de gestoría integral, garantizando que cada paso del camino hacia la ciudadanía sea lo más fluido y sin complicaciones posible.</p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={600}>
                <a href="/ciudadania-espanola">
                  <div className="icon-box">
                    <div className="icon"><i className="bx bx-world" /></div>
                    <h4>Ciudadanía Española</h4>
                    <br/>
                    <p>Ofrecemos servicios de gestoría integral, garantizando que cada paso del camino hacia la ciudadanía sea lo más fluido y sin complicaciones posible.</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>{/* End Services Section */}
        {/* ======= Cta Section ======= */}
        <section id="cta" className="cta">
          <div className="container" data-aos="zoom-in">
            <div className="text-center">
              <h3 className='all-capitals'>¿Necesitas asesoramiento?</h3>
              <a href="https://wa.me/+5491124089830?text=Hola%20necesito%20asesoramiento" className="cta-button">Consulta sin cargo</a>
            </div>
          </div>
        </section>{/* End Cta Section */}
      </div>
      <div>
        {/* ======= Team Section =======
        <section id="equipo" className="team">
          <div className="container">
            <div className="section-title">
              <span>Equipo</span>
              <h2>Equipo</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in">
                <div className="member">
                <img src="assets/img/team/dra_vaca.jpg" alt="doctora maricel vaca"  />
                  <h4>Dra. Maricel Vaca</h4>
                  <span>Abogada</span>
                  <p cl"^2.117.0",assName="justified">Abogada con amplia experiencia en diversas áreas del derecho, incluyendo derecho laboral, comercial y civil.</p>
                  <p className="justified">En 2024 fundé mi propio Estudio Jurídico MFV ABOGADOS, donde me especializo en casos relacionados con accidentes de trabajo, enfermedades profesionales y gestoría para la obtención de ciudadanía italiana y española.</p>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/maricelvaca/"><i className="bi bi-linkedin" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> End Team Section */}
        {/* ======= Contact Section ======= */}
        <Contact />
        {/* End Contact Section */}
      </div>

      </main>{/* End #main */}
    <Footer/>
    </>
  );
}

export default Main;
