import Header from './Header'
import Footer from './Footer'

function CiudadaniaEspanola() {
    const title =  'Ciudadanía española'
    return (
        <>
            <Header/>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section id="breadcrumbs" className="breadcrumbs">
                  <div className="container">
                    <ol>
                      <li><a href="/">Inicio</a></li>
                      <li>{title}</li>
                    </ol>
                    {/*h2>{title}</h2*/}
                    <div className="row">
                      <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                        <img src="assets/img/ciudadania-espanola.png" className="img-fluid" alt="ciudadanía española" style={{'padding': '5%'}} />
                      </div>
                      <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                        <h3>{title}</h3>
                        <p className="justified-text">La Ley de Memoria Democrática española (<a href="https://www.boe.es/buscar/doc.php?id=BOE-A-2022-17099" className='black-link'>Ley 20/2022</a>) o “LEY DE NIETOS” como normalmente se la llama, da la posibilidad de adquirir la Nacionalidad española por opción.</p>
                        <p className='centered'><strong>LA LEY 20/2022 DE MEMORIA DEMOCRÁTICA ESTARÁ VIGENTE HASTA EL 21/10/2024.</strong></p>
                        <p className='justified-text'>No pierdas más tiempo y energía preocupándote por la documentación. Permítenos encargarnos de todo el proceso para que puedas enfocarte en lo que realmente importa: tu futuro y el de tu familia. Contáctanos hoy mismo para comenzar tu camino hacia la ciudadanía de manera fácil y segura </p>
                      </div>
                    </div>
                  </div>
                </section>{/* End Breadcrumbs */}
            </main>
            <Footer/>
        </>
    );
}

export default CiudadaniaEspanola;
