import { useState, useRef } from 'react'
import { sendEmail, validateCaptcha } from './api'
import ReCAPTCHA from 'react-google-recaptcha'


function Contact() {
    const [isActive, setActive] = useState(false);
    const [isSuccessActive, setSuccessActive] = useState(false);
    const [isFailureActive, setFailureActive] = useState(false);
    const recaptchaRef = useRef()
    const [data, setData] = useState({
        source_email: "no-reply@mfvabogados.com.ar",
        destination_emails: ["mvaca@mfvabogados.com.ar"],
        email_subject: "",
        email_content: "",
        contact_email_address: "",
        name: ""
    })
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            alert('Por favor completa el captcha')
        }
        else {
            try {
                setActive(true)
                const response = await validateCaptcha(recaptchaValue)
                const success = response["success"] 
                if (success) {
                    const response = await sendEmail(data)
                    setActive(false)
                    setSuccessActive(true)
                    console.log(response)
                }
                else {
                    alert('Por favor completa el captcha correctamente')
                }
            }
            catch (error) {
                console.error(error)
                setActive(false)
                setFailureActive(true)
            }
        }
        e.target.reset();
        recaptchaRef.current.reset();
    }

    return (
        <>
            <section id="contacto" className="contact">
                <div className="container">
                    <div className="section-title">
                        <span>Contacto</span>
                        <h2>Contacto</h2>
                    </div>
                    <div className="row" data-aos="fade-up">
                        <div className="col-lg-4">
                            <div className="info-box mb-4">
                                <i className="bx bx-map" />
                                <h3>Dirección</h3>
                                <p>Av. Córdoba 1351 <br /> CP: 1055, CABA</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="info-box  mb-4">
                                <i className="bx bx-envelope" />
                                <h3>Email</h3>
                                <p>info@mfvabogados.com.ar <br /><br /></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="info-box  mb-4">
                                <i className="bx bx-phone-call" />
                                <h3>Teléfono</h3>
                                <p>11-2408-9830<br /><br /></p>
                            </div>
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up">
                        <div className="col-lg-6 ">
                            <iframe className="mb-4 mb-lg-0" title="ubicación" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.1990288717316!2d-58.38878592464349!3d-34.59912837295591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccac7793712d7%3A0x7d0061ef3a37f7c0!2sAv.%20C%C3%B3rdoba%201351%2C%20C1055AAF%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1707160991974!5m2!1ses-419!2sar" style={{ border: 0, width: '100%', height: 384 }} loading="lazy" referrerPolicy="no-referrer-when-downgrade" allowFullScreen />
                        </div>
                        <div class="col-lg-6">
                            <form onSubmit={handleSubmit} class="php-email-form">
                                <p class="sapurri">Dejanos tu consulta</p>
                                <div class="row">
                                    <div class="col-md-6 form-group">
                                        <input type="text" name="name"  onChange={handleChange} class="form-control" id="name" placeholder="Nombre" maxLength={50} required />
                                    </div>
                                    <div class="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" class="form-control" onChange={handleChange} name="contact_email_address" id="email" maxLength={120} placeholder="Email" required />
                                    </div>
                                </div>
                                <div class="form-group mt-3">
                                    <input type="text" class="form-control" onChange={handleChange} name="email_subject" id="subject" placeholder="Asunto" maxLength={125} required />
                                </div>
                                <div class="form-group mt-3">
                                    <textarea class="form-control"  onChange={handleChange} name="email_content" rows="5" placeholder="Mensaje" maxLength={500} required></textarea>
                                </div>
                                {/*<div class="form-group mt-3">*/}
                                <div class="row">
                                    <div class="col-md-6 form-group mt-3">
                                        <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} size="normal"/>
                                    </div>
                                </div>
                                {/*</div>*/}
                                <div class="my-3">
                                    <div class="loading" style={{display: isActive ? 'block' : 'none' }}>Loading</div>
                                    <div class="error-message" style={{display: isFailureActive ? 'block' : 'none' }}>No pudimos enviar tu mensaje</div>
                                    <div class="sent-message" style={{display: isSuccessActive ? 'block' : 'none' }}>Su mensaje ha sido enviado. ¡Gracias!</div>
                                </div>
                                <div class="text-center"><button type="submit">Enviar mensaje</button></div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact