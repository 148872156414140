import Header from './Header'
import Footer from './Footer'

function CiudadaniaItaliana() {
    const title =  'Ciudadanía italiana'
    return (
        <>
            <Header/>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <section id="breadcrumbs" className="breadcrumbs">
                  <div className="container">
                    <ol>
                      <li><a href="/">Inicio</a></li>
                      <li>{title}</li>
                    </ol>
                    {/*h2>{title}</h2*/}
                    <div className="row">
                      <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left">
                        <img src="assets/img/ciudadania-italiana.png" className="img-fluid" alt="ciudadanía italiana" style={{'padding': '10%'}} />
                      </div>
                      <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right">
                        <h3>{title}</h3>
                        <p className="justified-text">Armar la carpeta para presentar ante el consulado italiano para solicitar la ciudadanía puede ser un proceso complejo.</p>
                        <p className="justified-text">A continuación, se explican algunas razones por las cuales es esencial contar con un gestor para llevar a cabo este procedimiento:</p>

                        <ul>
                          <li className='justified-text'><i className="bi bi-check-circle" /> <strong>Conocimiento especializado:</strong> Somos especialistas en la búsqueda de toda la documentación necesaria para que reciba su tan ansiado e-mail del consulado con el asunto “conferma la cittadinanza”.</li>
                          <li className='justified-text'><i className="bi bi-check-circle" /> <strong>Gestión de Traducciones:</strong> Tenemos traductores y nos encargamos de que sus documentos cuenten con las traducciones al italiano de manera adecuada  asegurándonos de que cumplan con los requisitos de cada consulado. No importa si hizo toda la carpeta con nosotros, en caso de que quiera optar únicamente por este servicio, contáctese con nosotros. </li>
                          <li className='justified-text'><i className="bi bi-check-circle" /> <strong>Legalizaciones y apostillas:</strong> Legalizamos y apostillamos su documentación. No importa si hizo toda la carpeta con nosotros, en caso de que quiera optar únicamente por este servicio, contáctase con nosotros. </li>
                          <li className='justified-text'><i className="bi bi-check-circle" /> <strong>Seguimiento y Comunicación:</strong> Le garantizamos que de principio a fin va a estar informado de cómo va su trámite. Esto es un trabajo en equipo ya que para la búsqueda de documentación necesitaremos que nos cuente todo lo que sepa de su AVO italiano/a. </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>{/* End Breadcrumbs */}
            </main>
            <Footer/>
        </>
    );
}

export default CiudadaniaItaliana;
