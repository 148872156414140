import { Routes, Route } from "react-router-dom"
import Main from './components/Main'
import AccidentesTrabajo from './components/AccidentesTrabajo'
import EnfermedadesProfesionales from './components/EnfermedadesProfesionales'
import CiudadaniaItaliana from "./components/CiudadaniaItaliana"
import CiudadaniaEspanola from "./components/CiudadaniaEspanola"
import NotFound from "./components/NotFound"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Main/> } />
        <Route path="accidentes-trabajo" element={ <AccidentesTrabajo/> } />
        <Route path="enfermedades-profesionales" element={ <EnfermedadesProfesionales/> } />
        <Route path="ciudadania-italiana" element={ <CiudadaniaItaliana/> } />
        <Route path="ciudadania-espanola" element={ <CiudadaniaEspanola/> } />
        <Route path='*' element={ <NotFound />}/>
      </Routes>
    </div>
  );
}

export default App;
